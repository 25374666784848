<div id="return-cancel-button-component" class="col col-auto d-flex justify-content-end return-button-col"
  *ngIf="hostedReturnConfig?.componentVisibility?.returnsCancellation?.active && (status === 'INITIATED' || status === 'ACCEPTED' || status === 'PENDING_APPROVAL' || status === 'PICKUP_STARTED')">
  <a type="button" href="javascript:void(0)" class="return-cancel-button" [class.return-cancel-summary]="summaryView"
    [class.return-summary-multi]="multiSkuSummary" (click)="openCancelModal(cancelConfirmModal, $event)">Cancel
    Return</a>
</div>
<ng-template #cancelConfirmModal let-modal>
  <div class="cancel-modal">
    <div class="confirm-modal-body">
      <div>Are you sure you want to cancel this return?</div>
      <div class="btn-container">
        <button class="btn-cancel-modal" (click)="closeCancelModal()">Not Now</button>
        <button *ngIf="!loader" class="btn-confirm" (click)="cancelReturn()">Confirm</button>
        <app-bubble-loader *ngIf="loader"></app-bubble-loader>
      </div>
    </div>
  </div>
</ng-template>
